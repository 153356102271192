<template>
    <pop
        :showMask="true"
        :allowClose="true"
        :outClose="false"
        customClass="cookie-preference-pop"
        :onClose="adRegisterClose"
    >
        <div class="cookie-preference">
            <div class="head-title">
                {{$t("common:cookie.diagTitle")}}
            </div>
            <div class="diag-content">
                <div class="nav-swiper">
                    <Swiper
                        class="swiper-container"
                        v-bind="navSwiperOption"
                        @init="onNavSwiperReady"
                    >
                        <Slide
                            v-for="(item,index ) in list"
                            :key="item.name"
                            :class="{'nav-active': currIndex == index}"
                            class="swiper-slide"
                            @click="navSlideClick(index)"
                        >
                            {{ item.title }}
                        </Slide>
                    </Swiper>
                </div>
                <div class="content-swiper">
                    <Swiper
                        class="swiper-container"
                        v-bind="contentSwiperOption"
                        @init="onContentSwiperReady"
                        @slideChange="onContentChange"
                    >
                        <Slide
                            v-for="(item ) in list"
                            :key="item.name"
                            class="swiper-slide"
                        >
                            <div class="desc">{{ item.desc }}</div>
                            <ul v-if="item.items && item.items.length > 0">
                                <li v-for="(child,idx) in item.items" :key="idx">
                                    <div class="text"><span>{{ child }}</span></div>
                                </li>
                            </ul>
                            <div class="open-switch" :class="{disabled:item.disabled }">
                                <span @click="onSwitch(item,!form[item.name])">{{ getSwitchLabel(item) }}</span>
                                <siteSwitch  v-if="item.name != COOKIE_LEVEL.necessary " :value="form[item.name]" @change="onSwitch(item,$event)" />
                            </div>
                        </Slide>
                    </Swiper>
                   
                </div>
            </div>
            <div class="diag-footer">
                <div class="coo-btn save" @click="onSave">{{$t("common:cookie.saveSettings")}}</div>
                <div class="left-btn">
                   
                    <div class="coo-btn reject" @click="setCookieAll(false)">{{$t("common:cookie.declineAll")}}</div>
                    <div class="coo-btn allow" @click="setCookieAll(true)">{{$t("common:cookie.acceptAll")}}</div>
                </div>
            </div>
        </div>
    </pop>
</template>


<script>
// cookie preference settings
import util from '@/common/util';
import { getCookieSetting , setCookieAll, setCookieSetting , COOKIE_LEVEL } from "../../cookieStatus";
import { Swiper, SwiperSlide, fixSwiperOptions } from '@/common/swiper';
import siteSwitch from "@/components/switch.vue"
export default {
    name:"cookie-preference",
    data(){
        return {
            currIndex:0,
            navSwiperOption: fixSwiperOptions({
                "slidesPerGroup": 1,
                "slidesPerView": "auto",
                centeredSlides: false,
                centeredSlidesBounds:false,
                "spaceBetween": 30,
                slideToClickedSlide: true,
                // freeMode: false,
            }),
            contentSwiperOption:fixSwiperOptions({
                "slidesPerGroup": 1,
                "slidesPerView": 1,
                centeredSlides: false,
            }),
            form: getCookieSetting(util),
            COOKIE_LEVEL
        }
    },
    computed:{
        list(){
            return [
                {   
                    name: COOKIE_LEVEL.necessary,
                    disabled: true,
                    title: this.$t("common:cookie.nTitle"),
                    desc: this.$t("common:cookie.nDesc"),
                    items:[
                        this.$t("common:cookie.nDesc1"),
                        this.$t("common:cookie.nDesc2"),
                        this.$t("common:cookie.nDesc3"),
                    ]
                },
                {   
                    name: COOKIE_LEVEL.performance,
                    title: this.$t("common:cookie.pTitle"),
                    desc: this.$t("common:cookie.pDesc"),
                    items:[
                        this.$t("common:cookie.pDesc1"),
                        this.$t("common:cookie.pDesc2"),
                        this.$t("common:cookie.pDesc3"),
                    ]
                },
                {   
                    name: COOKIE_LEVEL.functional,
                    title: this.$t("common:cookie.fTitle"),
                    desc: this.$t("common:cookie.fDesc"),
                    items:[
                        this.$t("common:cookie.fDesc1"),
                        this.$t("common:cookie.fDesc2"),
                        this.$t("common:cookie.fDesc3"),
                    ]
                },
                {   
                    name: COOKIE_LEVEL.targeting,
                    disabled: this.form.dnt,
                    title: this.$t("common:cookie.tgTitle"),
                    desc: this.$t("common:cookie.tgDesc"),
                    items:[
                        this.$t("common:cookie.tgDesc1"),
                        this.$t("common:cookie.tgDesc2"),
                        this.$t("common:cookie.tgDesc3"),
                    ]
                }
                // ,
                // {   
                //     name: COOKIE_LEVEL.thirdParty,
                //     title: this.$t("common:cookie.tpTitle"),
                //     desc: this.$t("common:cookie.tpDesc"),
                //     items:[]
                // }
            ]
        }
    },
    components: {
        Swiper,
        Slide: SwiperSlide,
        siteSwitch
    },
    methods: {
        navSlideClick(index){
            this.currIndex = index;
            this.navSwiper && this.navSwiper.slideTo(index);
            this.contentSwiper && this.contentSwiper.slideTo(index);
        },
        onNavSwiperReady(swiper){
            this.navSwiper = swiper;
        },
        onContentSwiperReady(swiper){
            this.contentSwiper = swiper;
        },
        onContentChange(swiper){
            this.currIndex = swiper.realIndex;
            this.navSwiper && this.navSwiper.slideTo( swiper.realIndex);
        },
        getSwitchLabel(item){
            if(item.name == COOKIE_LEVEL.necessary){
                return this.$t("common:cookie.alwaysActive");
            }
            return  this.form[item.name] ? this.$t("common:cookie.active"):this.$t("common:cookie.inactive");
        },
        onSwitch(item,val){
            if(item.disabled){
                return ;
            }
            this.form[item.name] = val;
        },
        onSave(){
            setCookieSetting(this.form,util).then(()=>{
                this.$EventBus.$emit("cookie_setting_has_set");
                this.destroy(true);
            });
        },
        setCookieAll(flag){
            setCookieAll(util,flag).then(()=>{
                this.$EventBus.$emit("cookie_setting_has_set");
                this.destroy(true);
            })
        }
    }
}
</script>
<style lang="scss" src="./index.scss">
   
</style>